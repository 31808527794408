import { gql } from '@apollo/client';

export const NUTRIENTS = gql`
  query Nutrients($siteId: ID!) {
    viewer {
      site(id: $siteId) {
        id
        blocks {
          id
          name
          alias
          lastUpdated
          nutrients {
            nitrate {
              serial
              min
              max
              gaugeLevels {
                name
                ratio
              }
              reading {
                unit
                value
              }
              trend
              status
            }
            salinity {
              serial
              min
              max
              gaugeLevels {
                name
                ratio
              }
              reading {
                unit
                value
              }
              trend
              status
            }
          }
        }
      }
    }
  }
`;
