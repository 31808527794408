// client
import { getApolloClient } from '../clients/apollo';
//graphQL
import { MANAGE_EVENT } from './manageEvent.graphql';

async function createEvent(eventDetail, eventId) {
  try {
    const response = await getApolloClient().mutate({
      mutation: MANAGE_EVENT,
      variables: {
        eventId,
        event: eventDetail,
      },
    });

    return { event: response };
  } catch (error) {
    if (error.networkError?.result?.errors) {
      return { errors: error.networkError.result.errors };
    } else {
      return { errors: [{}] };
    }
  }
}

export { createEvent };
