// client
import { getApolloClient } from '../clients/apollo';

//graphQL
import { CREATE_SCHEDULE_EVENTS } from './createScheduleEvents.graphql';

async function createScheduleEvents(scheduleEvents) {
  try {
    const response = await getApolloClient().mutate({
      mutation: CREATE_SCHEDULE_EVENTS,
      variables: { sc: scheduleEvents },
    });

    return { event: response };
  } catch (error) {
    if (error.networkError?.result?.errors) {
      return { errors: error.networkError.result.errors };
    } else {
      return { errors: [{}] };
    }
  }
}

export { createScheduleEvents };
