import { View } from 'react-native';
import PropTypes from 'prop-types';

// proptypes
import { navigationShape } from '../../shapes/navigation';

// entity
import BlockEntity from '../../models/entities/blockEntity';

// icons
import icnNavLeft from '../../assets/icons/icnNavLeft.png';
import icnNavRight from '../../assets/icons/icnNavRight.png';

// styles
import { globalStyles } from '../../styles';

// components
import TouchIconButton from '../button/TouchIconButton';
import BurgerMenuButton from './BurgerMenuButton';
import Title from './Title';

const BlocksSelectorHeader = ({ navigation, screenName, currentBlock, siteName, changeBlock, blocks }) => {
  const blocksLength = blocks.length;
  const hasMultipleBlocks = blocksLength > 1;

  let selectedIndex;

  if (currentBlock && hasMultipleBlocks) {
    selectedIndex = blocks.findIndex((block) => {
      return block.id === currentBlock.id;
    });
  }

  const onLeftArrowClick = () => {
    const previousIndex = (selectedIndex + blocksLength - 1) % blocksLength;
    changeBlock(previousIndex);
  };

  const onRightArrowClick = () => {
    const nextIndex = (selectedIndex + 1) % blocksLength;
    changeBlock(nextIndex);
  };

  return (
    <View style={globalStyles.headerContainer}>
      <BurgerMenuButton screenName={screenName} navigation={navigation} />

      <View style={globalStyles.containerFullSizeRow}>
        {hasMultipleBlocks ? (
          <TouchIconButton
            icon={icnNavLeft}
            onPress={onLeftArrowClick}
            iconStyle={globalStyles.icnNav}
            testId={'header__left-arrow'}
          />
        ) : null}

        <View style={globalStyles.headerTitle}>
          <Title value={siteName} type="title" />
        </View>

        {hasMultipleBlocks ? (
          <TouchIconButton
            icon={icnNavRight}
            onPress={onRightArrowClick}
            iconStyle={globalStyles.icnNav}
            testId={'header__right-arrow'}
          />
        ) : null}
      </View>

      <View style={globalStyles.headerActions} />
    </View>
  );
};

BlocksSelectorHeader.propTypes = {
  navigation: navigationShape.isRequired,
  screenName: PropTypes.string.isRequired,
  currentBlock: PropTypes.instanceOf(BlockEntity),
  siteName: PropTypes.string.isRequired,
  changeBlock: PropTypes.func.isRequired,
  blocks: PropTypes.arrayOf(PropTypes.instanceOf(BlockEntity)),
};

export default BlocksSelectorHeader;
