import { View, StyleSheet } from 'react-native';
import PropTypes from 'prop-types';

// entities
import GaugeChartEntity from '../../models/entities/gaugeChartEntity';

import { NutrientStatus } from '../../models/entities/nutrientEntity';

// components
import ChartComponent from '../graph/ChartComponent';

const CHART_WIDTH = 400;
const CHART_HEIGHT = CHART_WIDTH / 2;

const GaugeChart = ({ data, showSerial, testId }) => {
  const { value, serial, min, max, unit, levels, trend, status } = data;

  const gauge = new GaugeChartEntity();

  if (showSerial) {
    gauge.updateTitle(serial);
  }

  if (status === NutrientStatus.COMMUNICATION_ERROR.value || status === NutrientStatus.OUT_OF_FIELD.value) {
    gauge.updateNoData('N/A');
  } else {
    gauge.updateTrend(trend);
    gauge.updateData(value, unit);
    gauge.updateLevels(levels);
  }

  gauge.updateMinMax(min, max);

  return (
    <View style={styles.container} testID={testId}>
      <ChartComponent height={CHART_HEIGHT} width={CHART_WIDTH} option={gauge} />
    </View>
  );
};

GaugeChart.propTypes = {
  data: PropTypes.object.isRequired,
  showSerial: PropTypes.bool,
  testId: PropTypes.string,
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
  },
});

export default GaugeChart;
