// client
import { getApolloClient } from '../clients/apollo';

//graphQL
import { DELETE_SCHEDULE_EVENT } from './deleteScheduleEvent.graphql';

async function deleteScheduleEvent(eventId) {
  try {
    const response = await getApolloClient().mutate({
      mutation: DELETE_SCHEDULE_EVENT,
      variables: { eventId },
    });

    return { event: response };
  } catch (error) {
    if (error.networkError?.result?.errors) {
      return { errors: error.networkError.result.errors };
    } else {
      return { errors: [{}] };
    }
  }
}

export { deleteScheduleEvent };
