import PropTypes from 'prop-types';
import { View, StyleSheet } from 'react-native';

// presenters
import NutrientsBlockAliasPresenter from '../../presenters/blockAlias/NutrientsBlockAliasPresenter';

//entity
import BlockEntity from '../../models/entities/blockEntity';

// constants
import COLORS from '../../colors';
import OPACITIES from '../../opacities';

// services
import { useTranslation } from '../../hooks/useTranslation';

// components
import BlockHeader from '../header/BlockHeader';
import NutrientsHeader from './NutrientsHeader';
import GaugeChart from './GaugeChart';

const NutrientsDetail = ({ selectedBlock }) => {
  const { t } = useTranslation();

  if (!selectedBlock) {
    return null;
  }

  const nitrates = selectedBlock.nitrates();
  const salinities = selectedBlock.salinities();
  const showNitratesSerial = nitrates?.length > 1;
  const showSalinitiesSerial = salinities?.length > 1;
  const hasNitrates = nitrates?.length > 0;
  const hasSalinities = salinities?.length > 0;
  const blockAliasPresenter = new NutrientsBlockAliasPresenter(selectedBlock);

  return (
    <>
      <View style={styles.container}>
        <BlockHeader presenter={blockAliasPresenter} testId={'nutrients__BlockHeader'} />
      </View>
      {hasNitrates > 0 && (
        <View style={styles.container}>
          <NutrientsHeader title={t('nitrate_status')} />
          {nitrates.map((nitrate, index) => (
            <GaugeChart key={index} data={nitrate} showSerial={showNitratesSerial} />
          ))}
        </View>
      )}
      {hasSalinities > 0 && (
        <View style={styles.container}>
          <NutrientsHeader title={t('salinity_status')} />
          {salinities.map((salinity, index) => (
            <GaugeChart key={index} data={salinity} showSerial={showSalinitiesSerial} />
          ))}
        </View>
      )}
    </>
  );
};

NutrientsDetail.propTypes = {
  selectedBlock: PropTypes.instanceOf(BlockEntity),
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    borderRadius: 4,
    marginHorizontal: 11,
    marginTop: 11,
    backgroundColor: COLORS.white,
    elevation: 2,
    shadowColor: COLORS.greyShadow,
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: OPACITIES.dataStreamButtonShadow,
    shadowRadius: 2,
  },
});

export default NutrientsDetail;
