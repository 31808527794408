// client
import { getApolloClient } from '../clients/apollo';

//graphQL
import { UPDATE_SCHEDULE_EVENT } from './updateScheduleEvent.graphql';

async function updateScheduleEvents(duration, eventId, startDate) {
  try {
    const response = await getApolloClient().mutate({
      mutation: UPDATE_SCHEDULE_EVENT,
      variables: { duration, eventId, startDate },
    });

    return { event: response };
  } catch (error) {
    if (error.networkError?.result?.errors) {
      return { errors: error.networkError.result.errors };
    } else {
      return { errors: [{}] };
    }
  }
}

export { updateScheduleEvents };
