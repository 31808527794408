import { Image } from 'react-native';

import COLORS from '../../colors';

export default class GaugeChartEntity {
  constructor() {
    this.title = {
      padding: [20, 0, 5, 40],
      textStyle: {
        fontSize: 14,
      },
    };
    this.series = [
      {
        type: 'gauge',
        startAngle: 180,
        endAngle: 0,
        center: ['50%', '85%'],
        radius: '145%',
        splitNumber: 8,
        axisLine: {
          lineStyle: {
            width: 16,
          },
        },
        pointer: {
          icon: 'path://M12.8,0.7l12,40.1H0.7L12.8,0.7z',
          length: '14%',
          width: 15,
          offsetCenter: [0, '-45%'],
          itemStyle: {
            color: 'auto',
          },
        },
        axisTick: {
          distance: 6,
          length: 8,
          lineStyle: {
            color: 'auto',
            width: 1,
          },
        },
        splitLine: {
          distance: 6,
          length: 12,
          lineStyle: {
            color: 'auto',
            width: 3,
          },
        },
        axisLabel: {
          show: true,
          distance: 21,
          /* istanbul ignore next */
          formatter(value) {
            return `${Math.round(value)}`;
          },
        },
        title: {
          offsetCenter: [0, '-10%'],
          fontSize: 20,
        },
        detail: {
          fontSize: 18,
          offsetCenter: [0, '0%'],
          valueAnimation: true,
        },
        markPoint: {
          symbolSize: 55,
          silent: true,
          data: [
            {
              name: 'trend',
              x: '50%',
              y: '65%',
            },
          ],
        },
      },
    ];
  }

  get s0() {
    return this.series[0];
  }

  updateTitle(title) {
    this.title.text = title;
  }

  updateNoData(text) {
    this.s0.markPoint.symbol = 'circle';
    this.s0.markPoint.itemStyle = {
      color: 'transparent',
    };
    this.s0.markPoint.label = {
      color: COLORS.greyishBrown,
      fontSize: 28,
      formatter: text,
    };
    this.s0.axisLine.lineStyle.color = [[1, COLORS.whisper]];
  }

  updateData(value, unit) {
    this.s0.data = [{ value }];
    this.s0.detail.formatter = function () {
      return `${unit} (N)`;
    };
  }

  calculateInterval(range) {
    if (range <= 10) {
      return 1;
    } else if (range <= 20) {
      return 2;
    } else {
      return Math.ceil(range / 50) * 5;
    }
  }

  updateMinMax(min, max) {
    this.s0.min = min;
    if (max === 0) {
      max = 100;
    }
    this.s0.max = max;

    const range = max - min;
    const interval = this.calculateInterval(range);
    this.s0.splitNumber = Math.max(1, Math.ceil(range / interval));
  }

  updateLevels(levels) {
    this.s0.axisLine.lineStyle.color = levels;
  }

  updateTrend(trend) {
    this.s0.markPoint.symbol = `image://${trend === '' ? '' : Image.resolveAssetSource(trend)?.uri}`;
  }
}
